
const home = {
  namespaced: true,
  state: {
    dialogFormLogin: false,
    formLogin: {
      email: {
        label: 'Email',
        type: 'email',
        value: ''
      },
      password: {
        label: 'Password',
        type: 'password',
        value: ''
      }
    },
    dialogFormRegister: false,
    formRegister: {
      email: '',
      password: ''
    },
    dialog: false,
    dialogVideo: false,
    class: 'col-mg-menu',
    banner: [
      {
        title: 'DIGITAL',
        src: require('@/assets/images/landingbg.webp')
      },
      {
        title: 'DIGITAL',
        src: require('@/assets/images/loperinhero.webp')
      },
      {
        title: 'DIGITAL',
        src: require('@/assets/images/collectionhero.webp')
      },
      {
        title: 'DIGITAL',
        src: require('@/assets/images/crmhero.webp')
      }
    ],
    potentialclients: [
      {
        title: 'BFI Finance',
        src: require('@/assets/images/bfifinance.png')
      },
      {
        title: 'TREASURY',
        src: require('@/assets/images/treasury.png')
      },
      {
        title: 'LOGTIFY',
        src: require('@/assets/images/logtify.png')
      },
      {
        title: 'AGREESIP',
        src: require('@/assets/images/agreesip.png')
      },
      {
        title: 'GROSIR MOTOR',
        src: require('@/assets/images/grosirmotor.png')
      },
      {
        title: 'GROSIR MOBIL',
        src: require('@/assets/images/grosirmobil.png')
      }
    ],
    highlightedworks: [
      {
        title: 'GROSIR MOBIL',
        src: require('@/assets/images/grosirmobilhw.webp')
      },
      {
        title: 'GROSIR MOTOR',
        src: require('@/assets/images/grosirmotorhw.webp')
      },
      {
        title: 'LOPERIN',
        src: require('@/assets/images/loperinhw.webp')
      },
      {
        title: 'AGREESIP',
        src: require('@/assets/images/agreesiphw.webp')
      },
      {
        title: 'RPM',
        src: require('@/assets/images/rpmhw.webp')
      },
      {
        title: 'PICKERS',
        src: require('@/assets/images/pickershw.webp')
      },
      {
        title: 'DINEGO',
        src: require('@/assets/images/dinegohw.webp')
      }
    ],
    bannerafterhw: [
      {
        title: 'COLLECTION',
        src: require('@/assets/images/collectionbanner.webp')
      },
      {
        title: 'LOGTIFY',
        src: require('@/assets/images/logtifybanner.webp')
      },
      {
        title: 'LOPERIN',
        src: require('@/assets/images/loperinbanner.webp')
      }
    ],
    ourhappyclient: [
      {
        title: 'HAPPYCLIENT',
        src: require('@/assets/images/clientpic.webp')
      },
      {
        title: 'CLIENTSFLAGS',
        src: require('@/assets/images/clientflags.png')
      }
    ],
    brands: [
      {
        title: 'Brand Identity',
        src: require('@/assets/images/brandmindmap.png'),
        desc: 'We are building an image through a brand by combining some components and shaping visual identity and starting to make a logo, typography, colours, packaging, etc., with the purpose that the brand has its image that will be attached to the target market'
      },
      {
        title: 'Social Media Campaign',
        src: require('@/assets/images/brandperson.png'),
        desc: 'We make digital campaigns to improve brand awareness of a product or brand, starting off with the concept for the campaign, content planning, creative production, and content management for social media activity.'
      },
      {
        title: 'Motion Graphic',
        src: require('@/assets/images/brandshape.png'),
        desc: 'We created motion graphic content for the company to promote their products and build brand awareness so that people could learn more about them. On their YouTube channel, we ran promotions and other social media activities.'
      },
      {
        title: 'Mural & Art Concept',
        src: require('@/assets/images/brandcreative.png'),
        desc: "Mural and Art Concept is one of our services by making a design concept to be implemented as a huge painting that is made to fill the wall that can be implemented for your needs inside and outside the room. This comes from one's need to visualise a message or as a medium to tell a message itself to others through paintings that have been made."
      }
    ],
    ProductDevelopment: [
      {
        title: 'Product Development',
        src: require('@/assets/images/products/1.png'),
        descKey: 'product1desc',
        desc: 'With a spirit to build digital-based products using design thinking, we are open to building aspiration and room for discussion to formulate mechanism that suits people, so we can make a product that is realised quickly and properly.',
        list: [
          { text: 'Empathy', icon: 'mdi-checkbox-blank-circle' },
          { text: 'Define', icon: 'mdi-checkbox-blank-circle' },
          { text: 'Ideation', icon: 'mdi-checkbox-blank-circle' },
          { text: 'Prototyping', icon: 'mdi-checkbox-blank-circle' },
          { text: 'Testing', icon: 'mdi-checkbox-blank-circle' }
        ]
      },
      {
        title: 'Mobile App Development',
        src: require('@/assets/images/products/2.png'),
        descKey: 'product2desc',
        desc: 'SolusiOne has experience and knowledge in building web and mobile based technological products, whether they are for start-up business as well an enterprise. We are using agile and waterfall software development, so that the time delivery will match for users needs.'
      },
      {
        title: 'System Development',
        src: require('@/assets/images/products/laptopproduct.png'),
        descKey: 'product3desc',
        desc: 'We are building a system that comes from a need to digitalise a process so that the work process can run optimally.'
      }
    ],
    logo: require('@/assets/SolusiOne.png'),
    logoFooter: require('@/assets/SolusiOne2.png'),
    copyright: 'Powered by SolusiOne',
    menu: [
      {
        text: 'Home',
        url: '/',
        icon: 'mdi-home'
      },
      {
        text: 'Services',
        url: '/Services',
        icon: 'mdi-handshake'
      },
      {
        text: 'Product',
        url: '/Product',
        icon: 'mdi-package-variant-closed'
      },
      // {
      //   text: 'Package', url: '/Package', icon: 'mdi-gift'
      // },
      {
        text: 'Blog',
        url: '/Blog',
        icon: 'mdi-earth'
      },
      {
        text: 'Contact',
        url: '/Contact',
        icon: 'mdi-phone'
      }
    ],
    ourservices: require('@/assets/images/ictservices.png'),
    ourproduct: require('@/assets/images/ourproduct.png'),
    WhoAreWe: require('@/assets/images/mockupwaw.webp'),
    WhoAreWe2: require('@/assets/images/whoarewe2.png'),
    WhoAreWe3: require('@/assets/images/whoarewe3.png'),
    productDevelopment: require('@/assets/images/product-development.png'),
    mobileAppDevelopment: require('@/assets/images/mobileAppDevelopment.png'),
    systemDevelopment: require('@/assets/images/systemDevelopment.png'),
    imageText: {
      digitalpartnershipenablement: '',
      ictservices: '',
      ourservices_h: '',
      ourproduct: '',
      ourproduct_h: '',
      ourteam_h: '',
      contactus: '',
      discuss: '',
      ourpackage: '',
      CreativeService: ''
    },
    join: {
      title: 'Join With Us!',
      instagram: '@solusione.id',
      email: 'contact@solusione.id',
      phone: '+62 815 1188 1986 \n +62 21 5081 3036'
    },
    products: {
      dinego: require('@/assets/images/products/dinegohw.png'),
      loperin: require('@/assets/images/products/loperinhw.png')
    },
    contact: [
      {
        logo: 'mdi-email-outline',
        text: 'Email',
        des: 'contact@solusione.id',
        url: 'mailto:contact@solusione.id'
      },
      {
        logo: 'mdi-phone-outline',
        text: 'Phone',
        des: '+62 815 1188 1986 <br> +62 21 5081 3036',
        url: 'https://wa.me/6281807484644'
      },
      {
        logo: 'mdi-instagram',
        text: 'Instagram',
        des: '@solusione.id',
        url: 'https://www.instagram.com/solusione.id/'
      },
      {
        logo: 'mdi-map-marker-outline',
        text: 'Graha Solusi Pratama',
        des: 'Jl. Lingkar Selatan, Serpong Kota Tangerang Selatan - 15340',
        url: ''
      },
      {
        logo: '',
        text: 'Ruko Icon Business Park 5',
        des: 'Jl. BSD Raya Utama Ruko E6, Kabupaten Tangerang, Banten',
        url: ''
      }
    ],
    clients: [
      {
        client: 'BFI',
        logo: require('@/assets/images/clients/bfi-logo.png')
      },
      {
        client: 'TREASURY',
        logo: require('@/assets/images/clients/tms-logo.png')
      },
      // {
      //   client: 'RPM', logo: require('@/assets/images/clients/rpm-logo.png')
      // },
      {
        client: 'LOGTIFY',
        logo: require('@/assets/images/clients/logtify-logo.png')
      },
      {
        client: 'AGREESIP',
        logo: require('@/assets/images/clients/agreesip-logo.png')
      },
      // {
      //   client: 'NEGO & DEAL', logo: require('@/assets/images/clients/nnd-logo.png')
      // },
      {
        client: 'GROSIR MOTOR',
        logo: require('@/assets/images/clients/gmot-logo.png')
      },
      {
        client: 'GROSIR MOBIL',
        logo: require('@/assets/images/clients/gmob-logo.png')
      }
      // {
      //   client: 'PICKERS', logo: require('@/assets/images/clients/pickers-logo.png')
      // },
      // {
      //   client: 'ALAMI', logo: require('@/assets/images/clients/alami-logo.png')
      // },
      // {
      //   client: 'IDENTALCARE', logo: require('@/assets/images/clients/idental-care-logo.png')
      // },
      // {
      //   client: 'ZAHRA STUDIO', logo: require('@/assets/images/clients/zahra-studio-logo.png')
      // },
      // {
      //   client: 'PEPTISOL', logo: require('@/assets/images/clients/peptisol-logo.png')
      // },
      // {
      //   client: 'IALF', logo: require('@/assets/images/clients/ialf-logo.png')
      // }
      // {
      //   client: '', logo: require('@/assets/images/clients/logo-gabung.png')
      // }
      // {
      //   client: 'BFI', logo: require('@/assets/images/clients/bfi-logo.png')
      // }
    ],
    teams: [
      {
        name: 'Eko',
        job: 'Bussiness Head',
        photo: require('@/assets/images/teams/eko.png')
      },
      {
        name: 'Irwan',
        job: 'Product Delivery Head',
        photo: require('@/assets/images/teams/irwan.png')
      },
      {
        name: 'Sunu',
        job: 'SME Technology Lead',
        photo: require('@/assets/images/teams/sunu.png')
      },
      {
        name: 'Wahyu',
        job: 'Development Technology Lead',
        photo: require('@/assets/images/teams/wahyu.png')
      },
      {
        name: 'Imam',
        job: 'ICT Solution Delivery Officer',
        photo: require('@/assets/images/teams/imam2.png')
      },
      {
        name: 'Okky',
        job: 'Solution Delivery Officer',
        photo: require('@/assets/images/teams/okky.png')
      },
      {
        name: 'Alvan',
        job: 'Solution Delivery Officer',
        photo: require('@/assets/images/teams/alvan2.png')
      },
      {
        name: 'Reza',
        job: 'Solution Delivery Officer',
        photo: require('@/assets/images/teams/reza.png')
      },
      {
        name: 'Kevin',
        job: 'Solution Delivery Officer',
        photo: require('@/assets/images/teams/kevin.png')
      }
    ],
    services: {
      pc: [
        require('@/assets/images/services/agreesip.png'),
        require('@/assets/images/services/dinego.png'),
        require('@/assets/images/services/gmob.png'),
        require('@/assets/images/services/gmot.png')
      ],
      mobile: [
        require('@/assets/images/services/loperin.png'),
        require('@/assets/images/services/pickers.png'),
        require('@/assets/images/services/rpm.png')
      ],
      double: [
        require('@/assets/images/services/agreesip.png'),
        require('@/assets/images/services/dinego.png'),
        require('@/assets/images/services/gmob.png'),
        require('@/assets/images/services/gmot.png'),
        require('@/assets/images/services/loperin.png'),
        require('@/assets/images/services/pickers.png'),
        require('@/assets/images/services/rpm.png')
      ],
      sm1: [
        require('@/assets/images/services/hp/1.png'),
        require('@/assets/images/services/hp/2.png'),
        require('@/assets/images/services/hp/3.png'),
        require('@/assets/images/services/hp/4.png'),
        require('@/assets/images/services/hp/5.png')
      ],
      sm2: [
        require('@/assets/images/services/hp/2_1.jpg'),
        require('@/assets/images/services/hp/2_2.jpg'),
        require('@/assets/images/services/hp/2_3.jpg'),
        require('@/assets/images/services/hp/2_4.jpg'),
        require('@/assets/images/services/hp/2_5.jpg')
      ],
      sm3: [
        require('@/assets/images/services/hp/3_1.jpg'),
        require('@/assets/images/services/hp/3_2.jpg'),
        require('@/assets/images/services/hp/3_3.jpg'),
        require('@/assets/images/services/hp/3_4.jpg'),
        require('@/assets/images/services/hp/3_5.jpg')
      ],
      mural: [
        require('@/assets/images/mural1.webp'),
        require('@/assets/images/mural2.webp'),
        require('@/assets/images/mural3.webp'),
        require('@/assets/images/mural4.webp')
      ],
      mural2: [
        require('@/assets/images/mural5.webp'),
        require('@/assets/images/mural6.webp'),
        require('@/assets/images/mural7.webp')
      ],
      muralMobile: [
        require('@/assets/images/mural1.webp'),
        require('@/assets/images/mural2.webp'),
        require('@/assets/images/mural4.webp'),
        require('@/assets/images/mural6.webp'),
        require('@/assets/images/mural7.webp')
      ],
      mural2Mobile: [
        require('@/assets/images/mural3.webp'),
        require('@/assets/images/mural5.webp')
      ]
    },
    mg1: [
      {
        title: 'MG 1.1',
        src: require('@/assets/images/mg1-1.webp')
      },
      {
        title: 'MG 1.2',
        src: require('@/assets/images/mg1-2.webp')
      },
      {
        title: 'MG 1.3',
        src: require('@/assets/images/mg1-3.webp')
      },
      {
        title: 'MG 1.4',
        src: require('@/assets/images/mg1-4.webp')
      },
      {
        title: 'MG 1.5',
        src: require('@/assets/images/mg1-5.webp')
      }
    ],
    mg2: [
      {
        title: 'MG 2.1',
        src: require('@/assets/images/mg2-1.webp')
      },
      {
        title: 'MG 2.2',
        src: require('@/assets/images/mg2-2.webp')
      },
      {
        title: 'MG 2.3',
        src: require('@/assets/images/mg2-3.webp')
      },
      {
        title: 'MG 2.4',
        src: require('@/assets/images/mg2-4.webp')
      },
      {
        title: 'MG 2.5',
        src: require('@/assets/images/mg2-5.webp')
      }
    ]
  },
  getters: {
    getBanner (state) {
      return state.banner
    },
    getClients (state) {
      return state.clients
    }
  },
  mutations: {
    SET_LOGO (state, logo) {
      state.logo = logo
    },
    SET_BANNER (state, banner) {
      state.banner = banner
    },
    SET_CLIENTS (state, clients) {
      state.clients = clients
    },
    SET_DIGITAL_PARTNERSHIP_ENABLEMENT (state, imageText) {
      state.imageText.digitalpartnershipenablement = imageText
    },
    SET_ICT_SERVICES (state, imageText) {
      state.imageText.ictservices = imageText
    },
    SET_OUR_SERVICES_H (state, imageText) {
      state.imageText.ourservices_h = imageText
    },
    SET_OUR_TEAM_H (state, imageText) {
      state.imageText.ourteam_h = imageText
    },
    SET_OUR_PRODUCT (state, imageText) {
      state.imageText.ourproduct = imageText
    },
    SET_OUR_PRODUCT_H (state, imageText) {
      state.imageText.ourproduct_h = imageText
    },
    SET_OUR_PACKAGE (state, ourpackage) {
      state.imageText.ourpackage = ourpackage
    },
    SET_CONTACT_US (state, imageText) {
      state.imageText.contactus = imageText
    },
    SET_DISCUSS (state, imageText) {
      state.imageText.discuss = imageText
    },
    SET_CREATIVE_SERVICE (state, imageText) {
      state.imageText.CreativeService = imageText
    },
    SET_TITLE_JOIN (state, title) {
      state.join.title = title
    },
    SET_DIALOG_LOGIN (state, payload) {
      state.dialogFormLogin = payload
    }
  },
  actions: {
    dialogFormLogin ({ commit }, payload) {
      commit('SET_DIALOG_LOGIN', payload)
    },
    changeBanner ({ commit }, payload) {
      commit('SET_BANNER', payload)
    },
    setDevice ({ commit }) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        alert('mobile')
      } else {
        alert('desktop')
      }
    },
    setTitleJoin ({ commit }, payload) {
      commit('SET_TITLE_JOIN', payload)
    },
    setImageText ({ commit }) {
      const dark = localStorage.getItem('dark_theme')
      if (dark) {
        if (dark === 'true') {
          commit('SET_LOGO', require('@/assets/SolusiOneDark.png'))
          commit(
            'SET_DIGITAL_PARTNERSHIP_ENABLEMENT',
            require('@/assets/images/imageText/white/digitalpartnershipenablement.png')
          )
          commit(
            'SET_ICT_SERVICES',
            require('@/assets/images/imageText/white/ictservices.png')
          )
          commit(
            'SET_OUR_SERVICES_H',
            require('@/assets/images/imageText/white/ourservices_h.png')
          )
          commit(
            'SET_OUR_TEAM_H',
            require('@/assets/images/imageText/white/ourteam.png')
          )
          commit(
            'SET_OUR_PRODUCT',
            require('@/assets/images/imageText/white/ourproduct.png')
          )
          commit(
            'SET_OUR_PRODUCT_H',
            require('@/assets/images/imageText/white/ourproduct_h.png')
          )
          commit(
            'SET_CONTACT_US',
            require('@/assets/images/imageText/white/contactus.png')
          )
          commit(
            'SET_DISCUSS',
            require('@/assets/images/imageText/white/discuss.png')
          )
          commit(
            'SET_CREATIVE_SERVICE',
            require('@/assets/images/imageText/white/CreativeService.png')
          )
          commit(
            'SET_OUR_PACKAGE',
            require('@/assets/images/imageText/white/OurPackage.png')
          )
        } else {
          commit('SET_LOGO', require('@/assets/SolusiOne.png'))
          commit(
            'SET_DIGITAL_PARTNERSHIP_ENABLEMENT',
            require('@/assets/images/imageText/black/digitalpartnershipenablement.png')
          )
          commit(
            'SET_ICT_SERVICES',
            require('@/assets/images/imageText/black/ictservices.png')
          )
          commit(
            'SET_OUR_SERVICES_H',
            require('@/assets/images/imageText/black/ourservices_h.png')
          )
          commit(
            'SET_OUR_TEAM_H',
            require('@/assets/images/imageText/black/ourteam.png')
          )
          commit(
            'SET_OUR_PRODUCT',
            require('@/assets/images/imageText/black/ourproduct.png')
          )
          commit(
            'SET_OUR_PRODUCT_H',
            require('@/assets/images/imageText/black/ourproduct_h.png')
          )
          commit(
            'SET_CONTACT_US',
            require('@/assets/images/imageText/black/contactus.png')
          )
          commit(
            'SET_DISCUSS',
            require('@/assets/images/imageText/black/discuss.png')
          )
          commit(
            'SET_CREATIVE_SERVICE',
            require('@/assets/images/imageText/black/CreativeService.png')
          )
          commit(
            'SET_OUR_PACKAGE',
            require('@/assets/images/imageText/black/OurPackage.png')
          )
        }
      } else {
        localStorage.setItem('dark_theme', false)
        commit('SET_LOGO', require('@/assets/SolusiOne.png'))
        commit(
          'SET_DIGITAL_PARTNERSHIP_ENABLEMENT',
          require('@/assets/images/imageText/black/digitalpartnershipenablement.png')
        )
        commit(
          'SET_ICT_SERVICES',
          require('@/assets/images/imageText/black/ictservices.png')
        )
        commit(
          'SET_OUR_SERVICES_H',
          require('@/assets/images/imageText/black/ourservices_h.png')
        )
        commit(
          'SET_OUR_TEAM_H',
          require('@/assets/images/imageText/black/ourteam.png')
        )
        commit(
          'SET_OUR_PRODUCT',
          require('@/assets/images/imageText/black/ourproduct.png')
        )
        commit(
          'SET_OUR_PRODUCT_H',
          require('@/assets/images/imageText/black/ourproduct_h.png')
        )
        commit(
          'SET_CONTACT_US',
          require('@/assets/images/imageText/black/contactus.png')
        )
        commit(
          'SET_DISCUSS',
          require('@/assets/images/imageText/black/discuss.png')
        )
        commit(
          'SET_CREATIVE_SERVICE',
          require('@/assets/images/imageText/black/CreativeService.png')
        )
        commit(
          'SET_OUR_PACKAGE',
          require('@/assets/images/imageText/black/OurPackage.png')
        )
      }
    },
    zoom ({ commit, state }, payload) {
      state.dialog = true
      state.file = payload
    },
    dialogVideo ({ commit, state }, payload) {
      state.dialogVideo = true
      state.file = payload
    }
  }
}

export default home
